import React, { useMemo, useLayoutEffect, useState, useEffect } from "react";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import findSiteType from "src/components/helpers/findSiteType";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import makeDynamicFormId from "src/components/helpers/makeDynamicFormId";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup";
import { getCommonHeroImages } from "src/utils";
import loadable from '@loadable/component'
import "src/scss/services/servicePages.scss";
import { SEO, CommonHero } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import { DEMO_FORM_LOADED } from "src/constants";
const ContentConnector = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.ContentConnector});
const ScrollLinks = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.SecondaryNavbar});
const Loader = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.Loader});


const ServicesTemplate = ({ pageContext, location, path }) => {
  const { locale } = pageContext;
  usePrependBaseURLIntoImages(["content-block-section tw-content-block-section"]);


  const formData = pageContext.relationships?.fieldRefMarketoForm;
  const { id, serial, gaLabel } = extractMarketoValues(
    formData?.fieldEmbedCode
  );
  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
    localStorage.setItem(DEMO_FORM_LOADED, true)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: true,
        key: DEMO_FORM_LOADED
      }
    }));
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);

  const aditionalCrumb = location.pathname !== "/services-support";
  const crumbs = [
    { title: "Home", link: locale === "en" ? "/" : "/uk/" },
    {
      title: "Services & Support",
      link: !aditionalCrumb ? null : "/services-support",
    },
  ];
  // If the page is /services-support itself there is no need for an additional crumbs
  // nor the link to the current page /services-support on the last crumb above
  if (aditionalCrumb) {
    crumbs.push({ title: pageContext.entityLabel });
  }

  useEffect(() => {
    localStorage.removeItem(DEMO_FORM_LOADED, false)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: false,
        key: DEMO_FORM_LOADED
      }
    }));
  }, []);

  useLayoutEffect(() => {
    const table = document.querySelector("table");
    if (table) {
      table.classList.add("table", "is-striped", "is-bordered", "is-fullwidth");
    }
  }, []);
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentConnector)) {
    contentConnectors = getContentConnectorFields(
      pageContext?.relationships?.fieldContentConnector,
      "servicePage"
    );
  }
  const anchors = getContentConnectorAnchors(contentConnectors);
  const heroImage = pageContext?.commonHeroProps?.heroImage
  const siteClass = findSiteType('mc') ? "service-template-mc" : findSiteType('ems') ? "service-template-ems" : ""
  const formTitle = pageContext?.relationships?.fieldRefMarketoForm?.title ? pageContext?.relationships?.fieldRefMarketoForm?.title : 'request a demo'
  let formId = makeDynamicFormId(formTitle)

  const OGMarkup = getOGMarkup(pageContext?.entityLabel, 'service', { gatsbyImage: heroImage?.shareImage });
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldVideoSchemaMarkup)
  const { entityMetatags: metaTags } = pageContext;

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    console.log("count", hreflangAlternatives.length)
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
})
  
const search = location.search;
hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives
const pageDataPath = getPageDataJsonPath(path)
const preloadImages = useMemo(()=>getCommonHeroImages(pageContext?.commonHeroProps),[pageContext?.commonHeroProps])

  return (
    <div className={"services-template-wrapper new-product-page tw-new-product-page service-template " + siteClass}>
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        title={pageContext?.entityLabel}
        meta={metaTags}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        preloadedImages={preloadImages}
        schemaMarkup={schemaMarkup}
        schemaMarkupVideo={schemaMarkupVideo}
        preloads={[pageDataPath]}
      />
      <CommonHero
        commonHeroProps={pageContext?.commonHeroProps}
        contentProp={"Service"}
      />
      {!!anchors?.length && (
        <ScrollLinks
          title={pageContext.fieldTermJumpLinksHeader}
          anchors={anchors}
          offset={-185}
        />
      )}
      <div className="container content-visibility-auto-mobile">
        <div className="content-section tw-min-h-[auto] tw-mb-[76px]">
          {!!pageContext.description?.value && (
            <section className="section">
              <div className="columns">
                <div
                  className="column is-10-desktop is-offset-1-desktop dangerously-html-handle"
                  dangerouslySetInnerHTML={{
                    __html: pageContext.description.value,
                  }}
                />
              </div>
            </section>
          )}
          {contentConnectors.map((data, index) => (
            <div key={index} className="section-container">
              <ContentConnector
                key={index}
                data={data}

                locale={locale}
              />
            </div>
          ))}

          {formTag && (
            <section className="taxonomy-form-wrapper section popup-form-handle">
              <div id={formId}></div>
              {!!pageContext?.relationships?.fieldRefMarketoForm?.fieldFormHeader && (
                <p className="is-size-5 has-text-centered mb-3 has-text-weight-bold">
                  {pageContext?.relationships?.fieldRefMarketoForm?.fieldFormHeader}
                </p>
              )}
              {!!pageContext?.relationships?.fieldRefMarketoForm?.fieldFormText && (
                <p
                  className="is-size-5 has-text-centered mb-3 dangerously-html-handle"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageContext?.relationships?.fieldRefMarketoForm?.fieldFormText
                        ?.value,
                  }}
                />
              )}
              {!isFormLoaded &&
                <div>
                  <Loader />
                </div>
              }
              {formTag}
            </section>
          )}
          {(location.pathname === "/cookies" || location.pathname === "/cookie" || location.pathname === "/cookie-list") && (
            <section className="section">
              {/* <!-- OneTrust Cookies List start --> */}
              <div id="ot-sdk-cookie-policy">&nbsp;</div>
              {/* <!-- OneTrust Cookies List end --> */}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesTemplate;
